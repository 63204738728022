
import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const MUC = () => (
    
    <ComponentWrapper>
    <StudyDesignOverview 
        title="A Pivotal Trial in LA/mUC"
        description="An open-label, randomized, multicenter study of KEYTRUDA + EV vs gem/plat in patients
        with previously untreated LA/mUC (N=886)" 
        data={[
        [
            {
                    title: 'Patient Eligibility',
                    description: `
                        <p><strong>Key Inclusion Criteria</strong></p>
                        <ul>
                            <li>Patients with LA/mUC who received no prior systemic therapy for locally advanced or metastatic disease</li>
                        </ul>
                            <p><strong>Key Exclusion Criteria</strong></p>
                        <ul>
                            <li>Active CNS metastases</li>
                            <li>Ongoing Grade ≥2 sensory or motor neuropathy</li>
                            <li>Uncontrolled diabetes (hemoglobin A1C [HbA1c] ≥8% or HbA1c ≥7% with associated symptoms)</li>
                        </ul>
                        <p>Randomization was stratified by cisplatin eligibility, PD-L1 expression, and presence of liver metastases.</p>
                    `,
                    type: 'box-gradient',
                    colspan: 2
                }
        ],
                [
                    {
                        type: 'arrow-green',
                        colspan: 2
                    }
                ],
                [
                    {
                        title: 'Randomized 1:1 (N=886)',
                        colspan: 2
                    }
                ],
    ]} />

    <StudyDesignArms title="Study Arm" arms={[
        {
                title: "KEYTRUDA + enfortumab vedotin (n=442)",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `KEYTRUDA 200&nbsp;mg<sup>a</sup> administered on day 1 of a 21-day cycle + enfortumab vedotin 1.25 mg&#8288;/&#8288;kg administered on days 1 and 8 of a 21-day cycle.`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `<p>Treatment was continued until disease progression or unacceptable toxicity. In the absence of disease progression or unacceptable toxicity, KEYTRUDA was continued for up to 2 years.</p>
`
                        }
                    ]
                ]
        },
       {
                title: "Gemcitabine + cisplatin or carboplatin (n=444)",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `Gemcitabine 1000&nbsp;mg/m<sup>2</sup> administered on days 1 and 8 of 21-day cycle + cisplatin 70 mg/m<sup>2</sup> or carboplatin (AUC = 4.5 or 5) administered on day 1 of a 21-day cycle.`,
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `<p>Treatment was continued until disease progression or unacceptable toxicity for up to 6 cycles.</p>`
                        }
                    ]
                ]
        }
    ]} />

        <StudyCopyBlock 
            title="End Points"
            description={`
                <p>Major Efficacy Outcome Measures</p>
                <ul>
                    <li>Overall survival (OS)</li>
                    <li>Progression-free survival (PFS)<sup>b</sup></li>
                </ul>
                <p>Additional Outcome Measure</p>
                <ul>
                    <li>Objective response rate (ORR)<sup>c</sup>
                    </li>
                </ul>
            `} 
        />

        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <ul>
                    <li><b>Cisplatin-eligible:</b> 54%</li>
                    <li><b>Cisplatin-ineligible:</b> 46%</li>
                    <li><b>Male:</b> 77%</li>
                    <li><b>Median age:</b> 69 years (range: 22 to 91)</li>
                    <li><b>Race:</b> White: 67%; Asian: 22%; Black or African American: 1%; Unknown or other: 10%</li>
                    <li><b>Ethnicity:</b> Hispanic or Latino: 12% </li>
                    <li><b>ECOG PS:</b> 0: 49%; 1: 47%; 2: 3%</li>
                    <li><b>A1C Status:</b> HbA1c <5.7%: 47%</li>
                    <li><b>Extent of disease:</b> Metastatic: 95%; Locally advanced: 5%</li>
                    <li><b>Metastatic site:</b> Visceral: 72%; Liver: 22%</li>
                    <li><b>Histology:</b> Urothelial carcinoma: 85%; Urothelial carcinoma mixed squamous differentiation: 6%; Urothelial carcinoma mixed other histologic variants: 2%</li>
                </ul>
            `} 
            footnotes={[
                {
                    label:'a.',
                    text: 'IV infusion over 30 minutes. When administered on the same day, KEYTRUDA was given approximately 30 minutes after enfortumab vedotin.'
                },
                {
                    label:'b.',
                    text: "As assessed by BICR according to RECIST v1.1."
                },
                {
                    label: "c.",
                    text: "As assessed by BICR."
                },
            ]}
            definitions={"AUC = area under the curve; BICR = blinded independent central review; CNS = central nervous system; ECOG = Eastern Cooperative Oncology Group; IV = intravenous; PD&#8288;-&#8288;L1 = programmed death ligand 1; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1."}
        />
</ComponentWrapper>
);

export default MUC
